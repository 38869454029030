import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueLazyLoad from 'vue3-lazyload'
import '@/style/index.css'
import 'uno.css'
const app = createApp(App)



app.use(router)

app.use(VueLazyLoad, {
  // options...
})
app.mount('#app')

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string
  }
  next()
})
