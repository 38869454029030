import { createRouter, createWebHistory } from 'vue-router'
// import SlotMachine from '@/views/slotMachine/SlotMachine.vue'
const QuizView = () => import('../views/lightGuess/QuizContainer.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/MerchantHomepage.vue'),
      meta: { title: '首藏' },
    },
    {
      path: '/merchantHomepage', // 商家首页
      name: 'MerchantHomepage',
      component: () => import('../views/MerchantHomepage.vue'),
      meta: { title: '首藏' },
    },
    {
      path: '/detail',
      name: 'Detail',
      component: () => import('../views/GroupBuyingDetails.vue'),
      meta: { title: '团购详情' },
    },
    {
      path: '/groupBuyingDetails', // 普通订单详情
      name: 'GroupBuyingDetails',
      component: () => import('../views/GroupBuyingDetails.vue'),
      meta: { title: '团购详情' },
    },
    {
      path: '/fortuneBox',
      name: 'FortuneBox',
      component: () => import('../views/FortuneBoxDetails.vue'),
      meta: { title: '团购详情' },
    },
    {
      path: '/fortuneBoxDetails', // 福盒订单详情
      name: 'FortuneBoxDetails',
      component: () => import('../views/FortuneBoxDetails.vue'),
      meta: { title: '团购详情' },
    },
    {
      path: '/integral',
      name: 'Integral',
      component: () => import('../views/IntegralView.vue'),
      meta: { title: '积分兑换' },
    },
    {
      path: '/notice',
      name: 'Notice',
      component: () => import('../views/NoticeView.vue'),
      meta: { title: '购票须知' },
    },
    {
      path: '/aboutNftfullScreenNoStatusBar',
      name: 'aboutNft',
      component: () => import('../views/AboutNft.vue'),
      meta: { title: '关于首藏' },
    },
    {
      path: '/shareLiveBroadcast', // 分享直播
      name: 'ShareLiveBroadcast',
      component: () => import('../views/ShareLiveBroadcast.vue'),
    },
    {
      path: '/liveBroadcastShare',
      name: 'LiveBroadcastShare',
      component: () => import('../views/ShareLiveBroadcast.vue'),
    },
    {
      path: '/downloadLink',
      name: 'Download',
      component: () => import('../views/DownloadView.vue'),
      meta: { title: '首藏' },
    },
    {
      path: '/null',
      name: 'Null',
      component: () => import('../views/DownloadView.vue'),
      meta: { title: '首藏' },
    },
    {
      path: '/download',
      redirect: '/downloadLink',
      meta: { title: '首藏' },
    },
    {
      path: '/cert',
      name: 'Cert',
      component: () => import('../views/NftCert.vue'),
      meta: { title: '首藏' },
    },
    {
      path: '/nftCert', //首藏证书
      name: 'NftCert',
      component: () => import('../views/NftCert.vue'),
      meta: { title: '首藏' },
    },
    // {
    //   path: '/drowserOpen',
    //   name: 'DrowserOpen',
    //   component: () => import('../views/DrowserOpen.vue'),
    // },
    {
      path: '/getYouHuiQuan',
      name: 'ReceiveCoupons',
      component: () => import('../views/ReceivePreferential.vue'),
      meta: { title: '领取优惠券' },
    },
    {
      path: '/receivePreferential', // 领取优惠券
      name: 'ReceivePreferential',
      component: () => import('../views/ReceivePreferential.vue'),
      meta: { title: '领取优惠券' },
    },
    {
      path: '/privacyAgreement',
      name: 'PrivacyAgreement',
      component: () => import('../views/PrivacyAgreement.vue'),
      meta: { title: '首藏平台隐私政策' },
    },
    {
      path: '/userServeAgreement',
      name: 'UserServeAgreement',
      component: () => import('../views/UserServeAgreement.vue'),
      meta: { title: '用户服务协议' },
    },
    // {
    //   path: '/dragonActivity',
    //   name: 'DragonActivity',
    //   component: () => import('../views/DragonActivity.vue'),
    //   meta: { title: '首藏-迎龙年' },
    // },
    {
      path: '/nftDragonActivity',
      name: 'NftDragonActivity',
      component: () => import('../views/NftDragonActivity.vue'),
      meta: { title: '首藏-迎龙年' },
    },
    {
      path: '/slotMachinefullScreenNoStatusBar',
      name: 'slotMachine',
      component: () => import('@/views/slotMachine/SlotMachine.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/newSlotMachinefullScreenNoStatusBar',
      name: 'newSlotMachine',
      component: () => import('@/views/newSlotMachine/SlotMachine.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/slotMachine', // 旧版本老虎机灰度用
      name: 'slotMachineOld',
      component: () => import('@/views/slotMachineOld/SlotMachine.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/myPrizefullScreenNoStatusBar',
      name: 'MyPrize',
      component: () => import('@/views/slotMachine/MyPrizeList.vue'),
      meta: { title: '我的奖品' },
    },
    {
      path: '/newMyPrizefullScreenNoStatusBar',
      name: 'NewMyPrize',
      component: () => import('@/views/newSlotMachine/MyPrizeList.vue'),
      meta: { title: '我的奖品' },
    },
    {
      path: '/newActivityRules',
      name: 'ActivityRules',
      component: () => import('@/views/newSlotMachine/ActivityRules.vue'),
      meta: { title: '活动细则及注意事项' },
    },
    {
      path: '/slotMachineDownload',
      name: 'SlotMachineDownload',
      component: () => import('@/views/slotMachine/SlotMachineDownload.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/newSlotMachineDownload',
      name: 'newSlotMachineDownload',
      component: () => import('@/views/newSlotMachine/SlotMachineDownload.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/slotMachineDownloadShare', // 老虎机下载分享页
      name: 'slotMachineDownloadShare',
      component: () => import('@/views/slotMachine/SlotMachineDownloadShare.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/agreement',
      name: 'Agreement',
      component: () => import('@/views/AgreementView.vue')
    },
    {
      path: '/lightGuessfullScreenNoStatusBar',
      name: 'LightGuess',
      component: () => import('@/views/lightGuess/LightGuess.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/quizViewfullScreenNoStatusBar',
      name: ' QuizView',
      component: QuizView,
      meta: { title: '\u200E' },
    },
    // {
    //   path: '/partitionRecordfullScreenNoStatusBar', // 瓜分记录
    //   name: ' PartitionRecord',
    //   component: () => import('@/views/lightGuess/PartitionRecord.vue'),
    //   meta: { title: '瓜分记录' },
    // },
    // {
    //   path: '/guessRulesfullScreenNoStatusBar',
    //   name: ' GuessRules',
    //   component: () => import('@/views/lightGuess/GuessRules.vue'),
    //   meta: { title: '猜灯谜规则' },
    // },
    {
      path: '/lanternRiddleDownload',
      name: ' LanternRiddleDownload',
      component: () => import('@/views/lightGuess/LanternRiddleDownload.vue'),
      meta: { title: '\u200E' },
    },
    {
      path: '/lanternRiddleDownloadShare', // 猜灯谜下载分享页
      name: ' LanternRiddleDownloadShare',
      component: () => import('@/views/lightGuess/LanternRiddleDownloadShare.vue'),
      meta: { title: '\u200E' },
    },

    // {
    //   path: '/countdownfullScreenNoStatusBar', // 倒计时
    //   name: ' Countdown',
    //   component: () => import('@/views/countdown/CountdownView.vue'),
    //   meta: { title: ' ' },
    // },

    {
      path: '/nftOfficialWebsiteDownload',
      name: ' nftOfficialWebsiteDownload',
      component: () => import('@/views/nftOfficialWebsiteDownload.vue'),
      meta: { title: ' ' },
    },
    {
      path: '/winningAnnouncementfullScreenNoStatusBar',
      name: 'winningAnnouncement',
      component: () => import('@/views/slotMachine/WinningAnnouncement.vue'),
      meta: { title: '\u200E' }, //老虎机中奖公示
    },
    {
      path: '/newWebActivity',
      name: 'NewWebActivity',
      component: () => import('@/views/NewWebActivity.vue'),
      meta: { title: '\u200E' }, // 打开新的H5页面
    },
    // 限时翻牌赢免单
    {
      path: '/presentFlopfullScreenNoStatusBar',
      name: ' presentFlop',
      component: () => import('@/views/presentFlop/index.vue'),
      meta: { title: '限时翻牌赢免单' },
    },
    {
      path: '/presentFlopRulefullScreenNoStatusBar',
      name: ' presentFlopRule',
      component: () => import('@/views/presentFlop/rule.vue'),
      meta: { title: '活动规则' },
    },
    {
      path: '/europeanCupFullScreenNoStatusBar',
      name: 'europeanCup',
      component: () => import('@/views/europeanCup/activityIndex/EuropeanCupIndex.vue'),
    },
    {
      path: '/europeanCupShare',
      name: 'europeanCupShare',
      component: () => import('@/views/europeanCup/activityIndex/EuropeanCupShare.vue'),
    },
    {
      path: '/europeanCupRule',
      name: 'europeanCupRule',
      component: () => import('@/views/europeanCup/activityIndex/RuleList.vue'),
      meta: {
        title: '活动规则'
      }
    },
    {
      path: '/europeanCupBeforeFullScreenNoStatusBar',
      name: 'europeanCupBefore',
      component: () => import('@/views/europeanCup/activityIndex/EuropeanCupBefore.vue'),
      meta: {
        title: '爱拼才会赢预热'
      }
    },
    {
      path: '/piecesGamefullScreenNoStatusBar',
      name: ' piecesGame',
      component: () => import('@/views/pokeRainGame/index.vue'),
      meta: { title: '爱拼才会赢游戏' },
    },
    {
      path: '/exhibitionIndex',
      name: 'exhibitionIndex',
      component: () => import('@/views/exhibitions/ExhibitionIndex.vue'),
      meta: {
        title: '展会首页'
      }
    },
    {
      path: '/exhibitionIndexShare',
      name: 'exhibitionIndexShare',
      component: () => import('@/views/exhibitions/ExhibitionIndexShare.vue'),
      meta: {
        title: '展会首页'
      }
    },
    {
      path: '/gachaMachine',
      component: () => import('@/views/exhibitions/MainView.vue'),
      meta: {
        title: '扭蛋机活动'
      }
    },
    {
      path: '/gachaMachineShare',
      component: () => import('@/views/exhibitions/MainViewShare.vue'),
      meta: {
        title: '扭蛋机活动'
      }
    },
    {
      path: '/summerPromotionfullScreenNoStatusBar',
      name: ' summerPromotion',
      component: () => import('@/views/summerPromotion/index.vue'),
      meta: { title: '惊喜一夏，商家大促' },
    }
  ]
})

export default router
